import MRXLogo from '../img/mrx-logo.jpg'
import CBALogo from '../img/cbabc-logo.png'
import HeardThatLogo from '../img/heardthat-logo.png'
import EMScreenshot from '../img/em-website.png'
import FathomLogo from '../img/fathom-logo.png'
import EnsembleLogo from '../img/ensemble-logo.png'

const mrxSkills = ["React.js", "HTML/CSS", "Java", "eXist-db"]
const cbaSkills = ["Kentico CMS", "Javascript", "HTML/CSS"]
const heardThatSkills = ["Node.js", "Javascript", "HTML/CSS", "SQLite"]
const fathomSkills = ["Flutter", "Flutter-Blue", "Python"]
const emSkills = ["React.js", "Next.js", "GraphQL", "Wordpress", "HTML/CSS", "Netlify"]

export default function Experience() {
    return (
        <div id="experience" className="section">
            <h1 className="light-text">experience</h1>
            <div className="col-2">
                <div className="center">
                    <a href="https://www.ensemble.com/" target="_blank" rel="noreferrer">
                        <img src={EnsembleLogo} alt='Ensemble Systems'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">Intermediate Software Developer</h2>
                    <span className="subtitle">June 2021 - Present</span>
                    <ul className="light-text">
                        <li>Developed user flows and canvas editing for Adobe GenStudio for Performance Marketing using Typescript and internal/external libraries.</li>
                        <li>Improved reusable web components for Adobe Journey Optimizer with Typescript and GraphQL.</li>
                        <li>Led a small team to build a React-based form on an (Adobe Experience Manager) AEM site for users to track their time.</li>
                        <li>Refactored and optimized a client’s website using AEM and AEM forms.</li>
                    </ul>
                    <div className="wrap">
                        {mrxSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-2">
                <div className="center">
                    <a href="http://www.mrxsolutions.com/" target="_blank" rel="noreferrer">
                        <img src={MRXLogo} alt='MRX Solutions'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">Junior Software Developer</h2>
                    <span className="subtitle">Sept 2019 - Aug 2020</span>
                    <ul className="light-text">
                        <li>Developed an interface with React-Redux for chiropractic clinic scheduling and booking of clients.</li>
                        <li>Prepared local server-side APIs in Java for in-house web applications.</li>
                        <li>Designed and developed client-customized web apps (eg Wait room screen).</li>
                        <li>Provide clients with support through customizing user profiles, software and systems maintenance and resolving bugs.</li>
                        <li>Improved workflow efficiency by writing a script to automating actions towards update process.</li>
                    </ul>
                    <div className="wrap">
                        {mrxSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-2">
                <div className="center">
                    <a href="https://www.cbabc.org/BarTalk/Home" target="_blank" rel="noreferrer">
                        <img src={CBALogo} alt='CBA BC'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">Junior Web Developer</h2>
                    <span className="subtitle">May 2019 - Aug 2019</span>
                    <ul className="light-text">
                        <li>Designed and developed the UI template for a new magazine microsite through Kentico CMS.</li>
                        <li>Improved loading times and accessibility (as evaluated by Google Lighthouse) by redesigning the site architecture.</li>
                        <li>Performed content migration of archived content into the new site.</li>
                        <li>Developed a script to scrape specific websites for data.</li>
                    </ul>
                    <div className="wrap">
                        {cbaSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
            <hr />
            <h1 className="light-text">projects</h1>
            <div className="col-2">
                <div className="center">
                    <a href="https://englishministry.rcefc.org/" target="_blank" rel="noreferrer">
                        <img src={EMScreenshot} alt='RCEFC English Ministries'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">RCEFC English Ministry Website</h2>
                    <span className="subtitle">Dec 2020</span>
                    <ul className="light-text">
                        <li>Improved performance by using Wordpress as a headless CMS and building a JAMstack site using Next.js and Netlify hosting.</li>
                        <li>Utilized various queries through Wordpress GraphQL to build the static site.</li>
                    </ul>
                    <div className="wrap">
                        {emSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
            <hr/>
            <div className="col-2">
                <div className="center">
                    <a href="https://www.heardthatapp.com/" target="_blank" rel="noreferrer">
                        <img src={HeardThatLogo} alt='HeardThat'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">Audio Browser </h2>
                    <span className="subtitle">May 2021</span>
                    <ul className="light-text">
                        <li>Worked in a group of five to build an in-house application for filtering through log files.</li>
                        <li>Designed and developed the user interface with bootstrap and pure javascript.</li>
                        <li>Setup a Node.js server to filter the data of up to thoasands of log files.</li>
                    </ul>
                    <div className="wrap">
                        {heardThatSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-2">
                <div className="center">
                    <a href="https://www.fathomscientific.com/" target="_blank" rel="noreferrer">
                        <img src={FathomLogo} alt='Fathom'></img>
                    </a>
                </div>
                <div>
                    <h2 className="light-text">QiQuac Mobile Application</h2>
                    <span className="subtitle">Jan 2021 - Apr 2021</span>
                    <ul className="light-text">
                        <li>Created a mobile app with Flutter to receive Bluetooth data for charting and custom data viewing.</li>
                        <li>Developed library for custom mathematical calculations.</li>
                    </ul>
                    <div className="wrap">
                        {fathomSkills.map(skill => <span className="tag">{skill}</span>)}
                    </div>
                </div>
            </div>
        </div>
    )
}