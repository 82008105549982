import LambertHeadshot from "../img/Lambert_Head_Shot.jpg";

const skills = [
  "React.js",
  "Next.js",
  "Javascript",
  "Typescript",
  "GraphQL",
  "HTML5/CSS3",
  "Netlify",
  "Java",
  "Python",
  "Git",
  "Jest",
];

export default function About() {
  return (
    <div id="about" className="section">
      <h1 className="light-text">about</h1>
      <div className="col-2">
        <div>
          <div className="profile-social">
            <img
              src={LambertHeadshot}
              alt="Lambert headshot"
              className="headshot"
              width="250px"
            ></img>
            <div className="social">
              <span>
                <a
                  href="mailto:lambert.lh.chan@gmail.com"
                  class="fa fa-envelope"
                >
                  {" "}
                </a>
              </span>
              <span>
                <a
                  href="https://www.facebook.com/lambochan1994/"
                  class="fa fa-facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/in/lambert-chan/"
                  class="fa fa-linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </span>
              <span>
                <a
                  href="https://github.com/lambert-chan"
                  class="fa fa-github"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="light-text">
          <p>
            With over 5 years of experience in development, I feel like I've
            only scratched the surface of what's possible. Yet, I continue to
            enjoy creating front-end experiences that empower users and help
            people reach their full potential.
          </p>
          <p>
            For the past few years I have been working with a variety of clients
            such as Adobe, Royal College of Physicians and Surgeons, and PwC,
            fulfilling their web application and website needs.
          </p>
        </div>
      </div>
      <h1 className="light-text">skills</h1>
      <div className="wrap">
        {skills.map((skill) => (
          <span className="tag">{skill}</span>
        ))}
      </div>
    </div>
  );
}
