export default function Header() {
    return (
        <div className="header">
            <div className="logo">
                <a href="/#">
                    lambertchan
                </a>
            </div>
            <ul className="navigation">
                <li>
                    <a href="/#about">about</a>
                </li>
                <li>
                    <a href="/#experience">experience</a>
                </li>
            </ul>
        </div>
    )
}